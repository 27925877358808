
export const getAPIURL = () => {

    // console.log("Welcome React");
    // console.log(process.env.NODE_ENV);
    var environment = process.env.NODE_ENV
    // console.log("Environment : " + environment);

    // const apiUrl = environment === 'development' ? 'http://localhost/omniwheels/bb-campus-api/' : 'https://api.campus-dev.suncolleges.ac.in/';
    // const apiUrl = environment === 'development' ? 'https://api.campus-dev.suncolleges.ac.in/' : 'https://api.campus-dev.suncolleges.ac.in/';
    // const apiUrl = environment === 'development' ? 'https://api.campus-uat.suncolleges.ac.in/' : 'https://api.campus-uat.suncolleges.ac.in/';

    // const apiUrl = 'http://localhost/omniwheels/bb-campus-api/';
    // const apiUrl = 'https://api.campus-dev.suncolleges.ac.in/';
    const apiUrl = 'https://api.campus-uat.suncolleges.ac.in/';
    // const apiUrl = 'https://api.campus.suncolleges.ac.in/';

    return apiUrl;
}

// getLicenseKey
export const getLicenseKey = 5566;